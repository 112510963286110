import { useContext, useState } from "react";
import { Dropdown } from "./Dropdown";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import { toast } from "react-hot-toast";
import { FeedbackIntegrationState, FeedbackSegmentConfig } from "../../reducers/feedbackIntegration";

interface AddLabelsModalProps {
    onClose: () => void;
}

export const AddLabelsModal = (props: AddLabelsModalProps) => {
    const [isDisplayDropdownOpen, setIsDisplayDropdownOpen] = useState(false);
    const [labelDisplayName, setLabelDisplayName] = useState("");
    const [label, setLabel] = useState("");
    const activateAddButton = labelDisplayName && label;


    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    const labelNames = data.segmentGroups.segments?.map(segment => segment.displayName);


    return (
        <>
            <div 
                className="fixed inset-0 z-[100]"
                onClick={props.onClose}
            />
            <div className="relative" id="add-labels-modal">
                <div className="absolute right-0 bg-white border border-gray-200 shadow-lg rounded-xl w-[400px] z-[101]">
                    <div className="p-4">
                        <div className="mb-4">
                            <label htmlFor="" className="text-sm text-gray-500 mb-1">Label</label>
                            <div className="relative">
                                <input 
                                    type="text"
                                    onChange={(e) => {
                                        setLabel(e.target.value);
                                    }}
                                    value={label} 
                                    placeholder="Type to create a new label" 
                                    className="rounded-md border border-gray-300 shadow-sm px-2 pt-1.5 pb-2 h-10 text-blueberry placeholder-gray-400 focus:outline-1 focus:outline-blueberry disabled:bg-gray-200 disabled:text-gray-500 w-full cursor-pointer" 
                                    id="add-labels-modal-label"
                                    autoComplete="off"
                                />    
                            </div>
                        </div>
                        <div className="mb-1">
                            <label htmlFor="" className="text-sm text-gray-500 mb-1">Display name</label>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    onSelect={() => {setIsDisplayDropdownOpen(true)}} 
                                    onChange={(e) => {setLabelDisplayName(e.target.value)}} 
                                    value={labelDisplayName} 
                                    placeholder="Type to create a new name" 
                                    className="rounded-md border border-gray-300 shadow-sm px-2 pt-1.5 pb-2 h-10 text-blueberry placeholder-gray-400 focus:outline-1 focus:outline-blueberry disabled:bg-gray-200 disabled:text-gray-500 w-full"
                                    id="add-labels-modal-display-name"
                                    autoComplete="off"
                                />    
                                <Dropdown 
                                    values={labelNames || []} 
                                    filterString={labelDisplayName} 
                                    onSelect={(value) => {
                                        setIsDisplayDropdownOpen(false);
                                        setLabelDisplayName(value);
                                    }} 
                                    isOpen={isDisplayDropdownOpen} 
                                    onClose={() => {setIsDisplayDropdownOpen(false)}}
                                    id="add-labels-modal-display-name-dropdown"
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div className="border-t-2 border-gray-200 py-6 px-8 lg:px-14 flex flex-col lg:flex-row gap-4 items-center justify-center">
                        <Button text="Add" shape={ButtonShape.Pill} icon={<CheckCircleIcon className="h-6 w-6" />} iconPosition={"left"} variant={ButtonVariant.Primary} 
                            onClick={() => {
                                const labelExists = checkIfLabelExists(label, labelDisplayName, data.feedbackIntegration!);
                                if (labelExists) {
                                    toast.error("Label already exists");
                                    return;
                                }
                                let newLabel = createLabel(
                                    labelDisplayName, 
                                    label, 
                                );
                                dispatch({
                                    type: FeedbackIntegrationActionType.AddSegments,
                                    payload: {
                                        segment: newLabel
                                    }
                                });
                                props.onClose();
                            }} 
                            disabled={!activateAddButton}
                            id="add-labels-modal-add-button"
                        />                            
                    </div>
                </div>
            </div>
        </>
    );
};

const checkIfLabelExists = (labelValue: string, labelDisplayName: string, feedbackIntegration: FeedbackIntegrationState) => {
    return feedbackIntegration.segmentConfig.find(config => {
        if (config.override === labelValue && config.segmentGroup.displayName === labelDisplayName) {
            return true;
        }
        return false;
    });
}

const createLabel = (customFieldDisplayName: string, labelValue: string) => {

    // Create new segment config with negative IDs
    const newSegmentConfig: FeedbackSegmentConfig = {
        path: "OVERRIDE",
        segmentGroup: {
            id: -1,
            displayName: customFieldDisplayName,
        },
        override: labelValue,
        id: -1,
    };

    return newSegmentConfig;
}