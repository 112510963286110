import uuid from 'react-uuid';
import {
  Chart_Bin_Type,
  Chart_Type,
  Y_Axis_Data,
  Breakdown,
  FilterInput,
  SeriesInput,
  PlotConfig,
  PlotFragment,
  PlotConfigurationOutput,
} from '../../generated/graphql';
import { computeFilterConsumable } from '../filterStatement/filterStatementReducer';

export interface ChartState {
  id: number;
  chartConfigs: PlotConfig;
  currentChart: PlotFragment | null;
  teamId: number;
  /**
   * True any time a chart is being loaded
   */
  loadingChart: boolean;
  /**
   * This is a random string that is used to determine if the plot configurations have changed.
   * This is used to trigger a re-fetch of the plot data when the plot configuration has changed.
   *
   * the reducer is responsible for setting this value to a new random string when the appropriate
   * settings are changed.
   */
  plotChanged: string;
}

export enum ChartActionType {
  SetChartConfigs = 'SetChartConfigs',
  SetCurrentChart = 'SetCurrentChart',
  SetBinType = 'SetBinType',
  UpdateChartTitle = 'UpdateChartTitle',
  UpdateChartType = 'UpdateChartType',
  UpdateYAxisMetric = 'UpdateYAxisMetric',
  UpdateBreakdown = 'UpdateBreakdown',
  SetSegmentGroupId = 'SetSegmentGroupId',
  SetLoadingChart = 'SetLoadingChart',
  SetSeriesTeamId = 'SetSeriesTeamId',
  SetSeriesFilterNode = 'SetSeriesFilterNode',
  AddSeries = 'AddSeries',
  RemoveSeries = 'RemoveSeries',
  SetChartId = 'SetChartId',
  ClearChartState = 'ClearChartState',
}

interface SetChartConfigsPayload {
  chartConfigs: PlotConfigurationOutput;
}

interface SetCurrentChartPayload {
  currentChart: PlotFragment;
}

interface SetBinTypePayload {
  binType: Chart_Bin_Type;
}

interface UpdateChartTitlePayload {
  title: string;
}

interface UpdateChartTypePayload {
  type: Chart_Type;
}

interface UpdateYAxisMetricPayload {
  yAxisMetric: Y_Axis_Data;
}

interface UpdateBreakdownPayload {
  breakdown: Breakdown;
}

interface UpdateSeriesPayload {
  series: SeriesInput[];
}

interface SetDateFilterPayload {
  dateFilter: FilterInput;
}

interface SetFilterInputPayload {
  filterInput: FilterInput;
}

interface SetSegmentGroupIdPayload {
  segmentGroupId: number;
}

interface SetLoadingChartPayload {
  loadingChart: boolean;
}

interface SetSeriesTeamIdPayload {
  teamId: number;
  index: number;
}

interface SetChartIdPayload {
  id: number;
}

interface RemoveSeriesPayload {
  index: number;
}

interface AddSeriesPayload {}

interface SetSeriesFilterNodePayload {
  index: number;
  filterNode: string;
}

interface ClearChartStatePayload {}

export type ChartPayload =
  | SetChartConfigsPayload
  | SetCurrentChartPayload
  | SetBinTypePayload
  | UpdateChartTitlePayload
  | UpdateChartTypePayload
  | UpdateYAxisMetricPayload
  | UpdateBreakdownPayload
  | UpdateSeriesPayload
  | ChartState
  | SetDateFilterPayload
  | SetFilterInputPayload
  | SetSegmentGroupIdPayload
  | SetLoadingChartPayload
  | AddSeriesPayload
  | SetSeriesFilterNodePayload
  | SetSeriesTeamIdPayload
  | SetChartIdPayload
  | ClearChartStatePayload;

export type ChartAction = { type: ChartActionType; payload: ChartPayload };

export const getInitialPlotState = (teamId: number): ChartState => ({
  id: -1,
  chartConfigs: {
    binSelection: Chart_Bin_Type.Dynamic,
    chartType: Chart_Type.Line,
    title: '',
    yAxisMetric: Y_Axis_Data.AbsoluteVolume,
    series: [
      {
        filterNode: computeFilterConsumable([], undefined),
        teamIdOverride: teamId,
        segmentGroupId: undefined,
      },
    ],
  },
  currentChart: null,
  plotChanged: '',
  teamId,
  loadingChart: false,
});

export const ChartReducer = (state: ChartState, action: { type: ChartActionType; payload: ChartPayload }): ChartState => {
  switch (action.type) {
    case ChartActionType.SetChartConfigs:
      return setChartConfigs(state, action);

    case ChartActionType.ClearChartState:
      return getInitialPlotState(state.teamId);

    case ChartActionType.SetLoadingChart:
      return {
        ...state,
        loadingChart: (action.payload as SetLoadingChartPayload).loadingChart,
      };

    case ChartActionType.SetCurrentChart:
      return setCurrentChart(state, action);

    case ChartActionType.SetBinType:
      return {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          binSelection: (action.payload as SetBinTypePayload).binType,
        },
        plotChanged: uuid(),
      };

    case ChartActionType.UpdateChartTitle:
      return {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          title: (action.payload as UpdateChartTitlePayload).title,
        },
      };

    case ChartActionType.UpdateChartType:
      return {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          chartType: (action.payload as UpdateChartTypePayload).type,
        },
        plotChanged: uuid(),
      };

    case ChartActionType.UpdateYAxisMetric:
      return {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          yAxisMetric: (action.payload as UpdateYAxisMetricPayload).yAxisMetric,
        },
        plotChanged: uuid(),
      };

    case ChartActionType.SetChartId:
      return {
        ...state,
        id: (action.payload as SetChartIdPayload).id,
      };

    case ChartActionType.UpdateBreakdown:
      /**
       * When changing the breakdown, we need to clear the segmentGroupId in the series configuration.
       */
      const newState = {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          breakdown: (action.payload as UpdateBreakdownPayload).breakdown as Breakdown,
          series: [
            {
              ...state.chartConfigs.series[0],
              segmentGroupId: undefined,
            },
          ],
        },
      };
      if (newState.chartConfigs.breakdown !== Breakdown.Segment) {
        /**
         * We only want to re-fetch the plot if the breakdown is not a segment breakdown, currently
         * when the user sets the breakdown to segment the segmentGroupId will be undefined. Not becuase we're setting
         * it to undefined but because it wasn't initially set. In the UI flow a user has to pick segment breakdown
         * then pick the segmentGroup they want.
         *
         * So if breakdown is a segment breakdown, we don't want to re-fetch the plot as this will throw an error on the backend
         */
        newState.plotChanged = uuid();
      }
      return newState;

    case ChartActionType.SetSegmentGroupId:
      // assert that there is only one series
      // set the segmentGroupId on the series

      const series = state.chartConfigs?.series;
      if (!series) {
        throw new Error('No series found');
      }

      if (series.length > 1) {
        throw new Error('More than one series found');
      }

      const segmentGroupId = (action.payload as SetSegmentGroupIdPayload).segmentGroupId;

      return {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          series: [{ ...series[0], segmentGroupId }],
        },
        plotChanged: uuid(),
      };

    case ChartActionType.AddSeries:
      // if you're adding a series, by definition, we now have more than one series - set the breakdown to undefined
      return {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          breakdown: undefined,
          series: [
            ...state.chartConfigs.series,
            { filterNode: computeFilterConsumable([], undefined), teamIdOverride: state.teamId, segmentGroupId: undefined },
          ],
        },
        plotChanged: uuid(),
      };

    case ChartActionType.RemoveSeries:
      return removeSeries(state, action);

    case ChartActionType.SetSeriesTeamId:
      const dataSeries = state.chartConfigs?.series;
      if (!dataSeries) {
        throw new Error('No series found');
      }

      const index = (action.payload as SetSeriesTeamIdPayload).index;
      const teamId = (action.payload as SetSeriesTeamIdPayload).teamId;

      const updatedSeries = [...dataSeries];
      // verify that the index is valid
      if (index < 0 || index >= updatedSeries.length) {
        throw new Error('Invalid index');
      }
      updatedSeries[index] = { ...updatedSeries[index], teamIdOverride: teamId, filterNode: computeFilterConsumable([], undefined) };

      return {
        ...state,
        chartConfigs: {
          ...state.chartConfigs,
          series: updatedSeries,
        },
        plotChanged: uuid(),
      };

    case ChartActionType.SetSeriesFilterNode:
      return setSeriesFilterNode(state, action);

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const setChartConfigs = (state: ChartState, action: { type: ChartActionType; payload: ChartPayload }): ChartState => {
  const chartConfigs = (action.payload as SetChartConfigsPayload).chartConfigs;
  if (!chartConfigs) {
    return { ...state };
  }
  return {
    ...state,
    chartConfigs: {
      breakdown: chartConfigs.breakdown,
      binSelection: chartConfigs.chartBinType,
      chartType: chartConfigs.chartType,
      title: chartConfigs.title,
      yAxisMetric: chartConfigs.yAxisMetric,
      series: chartConfigs.seriesConfig.map((series) => {
        return {
          filterNode: series.filterNode,
          segmentGroupId: series.segmentGroupId,
          teamIdOverride: series.team.id,
        };
      }),
    },
  };
};

const setCurrentChart = (state: ChartState, action: { type: ChartActionType; payload: ChartPayload }): ChartState => {
  return {
    ...state,
    currentChart: (action.payload as SetCurrentChartPayload).currentChart,
  };
};

const setSeriesFilterNode = (state: ChartState, action: { type: ChartActionType; payload: ChartPayload }): ChartState => {
  const index = (action.payload as SetSeriesFilterNodePayload).index;
  const filterNode = (action.payload as SetSeriesFilterNodePayload).filterNode;

  const series = state.chartConfigs?.series;
  if (!series) {
    throw new Error('No series found');
  }

  // verify that the index is valid
  if (index < 0 || index >= series.length) {
    throw new Error('Invalid index');
  }

  const updatedDataSeries = [...series];
  const currentSeries = updatedDataSeries[index];
  updatedDataSeries[index] = { ...currentSeries, filterNode };

  let plotChanged = state.plotChanged;
  if (currentSeries.filterNode !== filterNode) {
    // There's a bit of a loop where the filterNode isn't actually changing but the useEffect is being triggered
    // this checks to see if the filterNode is actually changing and if it is, we set the plotChanged to a new uuid
    plotChanged = uuid();
  }

  return {
    ...state,
    chartConfigs: { ...state.chartConfigs, series: updatedDataSeries },
    plotChanged: plotChanged,
  };
};

const removeSeries = (state: ChartState, action: { type: ChartActionType; payload: ChartPayload }): ChartState => {
  const dataSeries = state.chartConfigs?.series;
  if (!dataSeries) {
    throw new Error('No series found');
  }

  // verify that the index is valid
  const index = (action.payload as RemoveSeriesPayload).index;
  // you can't remove the first series
  if (index < 1 || index >= dataSeries.length) {
    throw new Error('Invalid index');
  }

  const updatedSeries = [...dataSeries];
  updatedSeries.splice(index, 1);

  return {
    ...state,
    chartConfigs: { ...state.chartConfigs, series: updatedSeries },
    plotChanged: uuid(),
  };
};
