import { Dropdown } from "../Dropdown";
import { useState, useContext } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FeedbackIntegrationFragment, ComparisonType } from "../../../../generated/graphql";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../../context/FeedbackIntegrationContext";
import Button, { ButtonShape, ButtonVariant } from "../../../baseComponents/Button";
import { FeedbackIntegrationState, FeedbackSegmentGroup } from "../../../reducers/feedbackIntegration";
import { FilterValueInOperatorModal } from "./FilterValueInOperatorModal";

interface FilterValueSelectionModalProps {
    onClose: () => void;
    segmentGroup: FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"];
    comparisonValue: string;
    setComparisonValue: (value: string) => void;
    handleUpdateFilter: (comparisonOperator: ComparisonType, comparisonValue: string, segmentGroup: FeedbackIntegrationFragment["segmentConfig"][number]["segmentGroup"]) => void;
    initialComparisonOperator?: ComparisonType;
    id: string;
}


export const FilterValueSelectionModal = (props: FilterValueSelectionModalProps) => {
    const [isComparisonOperatorDropdownOpen, setIsComparisonOperatorDropdownOpen] = useState(false);
    const [comparisonOperator, setComparisonOperator] = useState(props.initialComparisonOperator ?? ComparisonType.Equal);

    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);
    const configsForGroup = data?.feedbackIntegration?.segmentConfig.filter(config => config.segmentGroup.displayName === props.segmentGroup.displayName);
    // Get all values from the datasource fields where the config path matches the datasource field path
    const uniqueValues = data.dataSourceFields.filter(field => configsForGroup.some(config => config.path === field.path));
    
    const formatOperator = (operator: string) => {
        return operator.split('_')
            .map(word => word.charAt(0) + word.slice(1).toLowerCase())
            .join(' ');
    };

    return (
        <div className="w-80 bg-silver relative flex flex-col overflow-hidden font-sofiapro">
            <div className="py-2 h-full overflow-y-auto">
                <div className="px-4 w-full gap-5 flex flex-col">
                    <div className="flex flex-row gap-2 items-center justify-start mt-2.5">
                        <h4 className="text-lg font-semibold text-blueberry">
                            {props.segmentGroup.displayName}
                        </h4>
                    </div>
                    <div className="flex flex-col gap-2">
                        <input 
                            readOnly 
                            value={props.segmentGroup.displayName}
                            className="input-primary text-gray-500 bg-gray-200 w-full text-left px-3 py-2 rounded-md border border-gray-300 flex justify-between items-center"
                        />
                        <button 
                            type="button"
                            className="input-primary bg-white w-full text-left px-3 py-2 rounded-md border border-gray-300 flex justify-between items-center"
                            onClick={() => setIsComparisonOperatorDropdownOpen(true)}
                        >
                            <span>{formatOperator(comparisonOperator)}</span>
                            <ChevronDownIcon className="w-4 h-4 text-gray-500" />
                        </button>
                        <Dropdown 
                            id="filter-value-selection-modal-comparison-operator-dropdown"
                            values={Object.values(ComparisonType)} 
                            filterString={''} 
                            onSelect={(value) => {
                                setIsComparisonOperatorDropdownOpen(false);
                                setComparisonOperator(value as ComparisonType);
                            }} 
                            isOpen={isComparisonOperatorDropdownOpen} 
                            onClose={() => {setIsComparisonOperatorDropdownOpen(false)}}
                        />
                        {comparisonOperator === ComparisonType.In || comparisonOperator === ComparisonType.NotIn ? 
                            <FilterValueInOperatorModal 
                                onClose={props.onClose}
                                value={props.comparisonValue}
                                onChange={props.setComparisonValue}
                                sampleValues={uniqueValues?.map(value => value.values[0])}
                            /> : 
                            <input  
                                value={props.comparisonValue}
                                onChange={(e) => props.setComparisonValue(e.target.value)}
                                placeholder={uniqueValues.length ? uniqueValues[0].values[0] : 'Loading sample values...'}
                                className="input-primary bg-white w-full text-left px-3 py-2 rounded-md border border-gray-300 flex justify-between items-center"
                                id={`${props.id}-filter-value-input`}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="border-t px-4 py-4 flex justify-between items-center w-full bg-silver h-16">
                <Button 
                    type="button" 
                    onClick={props.onClose}
                    text="Cancel"
                    shape={ButtonShape.Pill}
                    variant={ButtonVariant.Tertiary}
                />
                <Button 
                    type="button" 
                    disabled={!props.comparisonValue.trim()}
                    text="Apply"
                    shape={ButtonShape.Pill}
                    variant={ButtonVariant.Primary}
                    onClick={() => {
                        props.handleUpdateFilter(comparisonOperator, props.comparisonValue, props.segmentGroup);
                        props.onClose();
                    }}
                    id={`${props.id}-filter-apply-button`}
                />          
            </div>
        </div>
    )
}