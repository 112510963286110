import { SparklesIcon } from '@heroicons/react/24/outline';
import { LowKeyTag } from '../../labels/LowKeyTag';
import { FC } from 'react';

export interface IFilterSources {
  sources?: string[];
  isAIGenerated?: boolean;
  justify?: 'start' | 'center' | 'end';
}

export const FilterSources: FC<IFilterSources> = ({ sources, isAIGenerated = false, justify = 'start' }) => {
  const isAI = isAIGenerated || sources?.includes('AI Generated');
  const realSources = sources?.filter((source) => !!source) ?? [];

  return (
    <div>
      {realSources.length ? (
        <div className={`flex flex-row gap-1 justify-${justify}  items-center flex-wrap`}>
          {isAI ? <SparklesIcon className="h-3 w-3 stroke-gray-500 pb-0.5" /> : <div className="text-gray-500 font-light text-xxs">Available from</div>}
          {isAI ? <div className="text-gray-500 font-light text-xxs">AI Generated</div> : realSources.map((source) => <LowKeyTag text={source} />)}
        </div>
      ) : null}
      {!isAI && !realSources.length ? <div className="text-gray-500 font-light text-xxs">Available by Default</div> : null}
    </div>
  );
};
