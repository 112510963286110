import { useEffect, useMemo } from 'react';
import { Breakdown, Chart_Type, useSegmentGroupLazyQuery, Y_Axis_Data } from '../../../generated/graphql';
import { useChartDispatch } from '../../../context/chartContext';
import { useChartState } from '../../../context/chartContext';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useState } from 'react';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { BreakdownMockList, ChartTypeList, PlotUnitMockList } from '../../pages/ChartsPage';
import { VirtualizedComboBox } from '../VirtualizedComboBox';
import { ChartActionType } from '../../../reducers/charts/chartReducer';
import Tippy from '@tippyjs/react';
import { classNames } from '../../../v2/util';
import { CustomIcon } from '../icons/CustomIcon';
import TooltipIcon from '../Modals/TooltipIcon';

const ChartTypeSelector = ({ selectedType, onSelect }: { selectedType: Chart_Type; onSelect: (type: Chart_Type) => void }) => {
  return (
    <div className="flex gap-2">
      {ChartTypeList.map((chartType) => (
        <Tippy key={chartType.id} content={chartType.name}>
          <button
            onClick={() => onSelect(chartType.value as Chart_Type)}
            className={classNames(
              'p-2 border rounded-md hover:bg-gray-100 transition-colors',
              selectedType === chartType.value ? 'bg-gray-300 hover:bg-gray-300' : 'hover:bg-gray-200'
            )}
          >
            {chartType.icon}
          </button>
        </Tippy>
      ))}
    </div>
  );
};

export interface IEditChartSettingsProps {
  disableBreakdown: boolean;
}

const EditChartSettings = ({ disableBreakdown }: IEditChartSettingsProps) => {
  const chartState = useChartState();
  const chartDispatch = useChartDispatch();
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [getFilterData, _] = useSegmentGroupLazyQuery({ variables: { teamId } });
  const [segments, setSegments] = useState<IDropDownItem[] | undefined>();

  useEffect(() => {
    getFilterData({
      onCompleted(data) {
        setSegments(
          data.segments?.map((segment) => {
            return { id: segment.id, name: segment.displayName };
          })
        );
      },
    });
  }, []);

  const handleChartTypeSelect = (type: Chart_Type) => {
    chartDispatch({
      type: ChartActionType.UpdateChartType,
      payload: { type },
    });
  };

  const breakdownOptions = useMemo(() => [...BreakdownMockList.filter((b) => b.value !== Breakdown.Segment), ...(segments ?? [])], [segments]);

  const handleBreakdownSelection = (selectedItem: IDropDownItem | undefined): void => {
    if (!selectedItem) return;

    if ('value' in selectedItem) {
      chartDispatch({
        type: ChartActionType.UpdateBreakdown,
        payload: { breakdown: selectedItem.value as Breakdown },
      });
      return;
    }

    chartDispatch({
      type: ChartActionType.UpdateBreakdown,
      payload: { breakdown: Breakdown.Segment },
    });

    const segmentGroupId = typeof selectedItem.id === 'number' ? selectedItem.id : parseInt(selectedItem.id);

    chartDispatch({
      type: ChartActionType.SetSegmentGroupId,
      payload: { segmentGroupId },
    });
  };

  const selectedBreakdownItem = useMemo((): IDropDownItem | undefined => {
    if (!chartState.chartConfigs) return undefined;

    const { breakdown, series } = chartState.chartConfigs;
    const segmentGroupId = series?.[0]?.segmentGroupId;

    return breakdownOptions.find((item) => item.id == segmentGroupId || item.value == breakdown || item.name?.toLowerCase() == breakdown?.toLowerCase());
  }, [breakdownOptions, chartState.chartConfigs]);

  return (
    <div className="flex flex-col bg-silver py-4 rounded-lg divide-y divide-gray-300">
      <div className="flex flex-row px-5 pb-4">
        <div className="w-32 flex items-center gap-x-2">
          <CustomIcon name="chart-type" className="w-4 h-4 2xl:w-5 2xl:h-5" />
          <p className="text-blueberry font-semibold text-sm 2xl:text-base">Chart Type</p>
        </div>
        <div className="flex-1">
          <ChartTypeSelector selectedType={chartState.chartConfigs?.chartType as Chart_Type} onSelect={handleChartTypeSelect} />
        </div>
      </div>
      <div className="flex flex-col gap-y-3 pt-4">
        <div className="flex flex-row px-5">
          <div className="w-32 flex items-center gap-x-2">
            <CustomIcon name="chart-plot-unit" className="w-4 h-4 2xl:w-5 2xl:h-5" />
            <p className="text-blueberry font-semibold text-sm 2xl:text-base">Plot Unit</p>
          </div>
          <div className="flex-1">
            <VirtualizedComboBox
              id="chart-plotunit-dropdown"
              comboBoxData={PlotUnitMockList}
              disableAlphabeticalSort
              disableClear
              setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                if (!selectedItem) return;
                chartDispatch({ type: ChartActionType.UpdateYAxisMetric, payload: { yAxisMetric: selectedItem.value as Y_Axis_Data } });
              }}
              selectedItem={PlotUnitMockList.find((item) => item.value === chartState.chartConfigs?.yAxisMetric)}
            />
          </div>
        </div>
        <div className="flex flex-row px-5">
          <div className="w-32 flex items-center gap-x-2">
            <CustomIcon name="chart-breakdown" className="w-4 h-4 2xl:w-5 2xl:h-5" />
            <p className="text-blueberry font-semibold text-sm 2xl:text-base">Breakdown</p>
          </div>
          <div className="flex-1 flex gap-x-2">
            <div className="flex flex-row justify-start items-center">
              <VirtualizedComboBox
                id="chart-breakdown-dropdown"
                disableAlphabeticalSort
                disableClear
                comboBoxData={breakdownOptions}
                setSelectedItem={handleBreakdownSelection}
                selectedItem={selectedBreakdownItem}
                disabled={disableBreakdown}
              />
              {disableBreakdown ? <TooltipIcon tooltipContent="Breakdown is disabled when multiple data sources are selected" styles="ml-2" /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditChartSettings;
