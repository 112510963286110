import { useMemo, useRef, useState } from 'react';
import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ChevronDownIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { IDropDownItem } from '../baseComponents/DropDown';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import Fuse from 'fuse.js';

interface VirtualizedComboBoxProps {
  id?: string;
  comboBoxData: IDropDownItem[];
  selectedItem?: IDropDownItem;
  setSelectedItem: (item: IDropDownItem | undefined) => void;
  useDisplayName?: boolean;
  placeholder?: string;
  defaultOption?: boolean;
  disableClear?: boolean;
  disableAlphabeticalSort?: boolean;
  loading?: boolean;
  disabled?: boolean;
  grayStyle?: boolean; //janky
  onClear?: () => void;
}

/**
 * Generic helper function to perform fuzzy search on a list of items.
 *
 * @param query  The string to search for
 * @param items  The source list of items
 * @param fuseKeys The object fields to target in the fuzzy search
 * @returns A filtered array of items matching the query
 */
export function fuzzySearch<T>(query: string, items: T[], fuseKeys: Array<keyof T>): T[] {
  const fuse = new Fuse(items, {
    keys: fuseKeys as string[], // as string[] to satisfy Fuse's config
    threshold: 0.3, // Adjust to make the search more or less fuzzy
  });
  const results = fuse.search(query);
  return results.map((r) => r.item);
}

export function VirtualizedComboBox({
  id,
  comboBoxData,
  selectedItem,
  setSelectedItem,
  placeholder,
  useDisplayName,
  defaultOption,
  disableClear,
  disableAlphabeticalSort,
  loading,
  disabled,
  grayStyle,
  onClear,
}: VirtualizedComboBoxProps) {
  const [query, setQuery] = useState('');

  function checkAndClearInput() {
    if (query !== '' && filteredItems.length == 0 && !defaultOption) {
      setQuery('');
    }
  }
  const defaultValue = { id: -1, displayName: query, name: query };

  const filteredItems = useMemo(() => {
    // If the user hasn't typed anything, skip fuzzy-search
    let items = query === '' ? comboBoxData : fuzzySearch<IDropDownItem>(query, comboBoxData, ['name', 'title']);

    if (!disableAlphabeticalSort) {
      items = items.slice().sort((a, b) => {
        const nameA = a.name || a.title || '';
        const nameB = b.name || b.title || '';
        return nameA.localeCompare(nameB);
      });
    }
    return items;
  }, [comboBoxData, query, disableAlphabeticalSort]);

  return (
    <Combobox
      id={id}
      data-testid={id}
      className="h-full group focus:border-0 outline-none focus:outline-none focus:ring-0"
      disabled={disabled}
      as="div"
      value={selectedItem}
      onChange={(item: IDropDownItem | undefined) => {
        if (query === '' && defaultOption) return;
        setSelectedItem(item);
        setQuery('');
      }}
      onFocus={() => checkAndClearInput()}
    >
      <div className="relative h-full">
        <div className={`relative h-full flex ${disabled ? 'bg-silver opacity-[0.66]' : ''}`}>
          <Combobox.Input
            tabIndex={disabled ? -1 : undefined}
            autoComplete="off"
            readOnly={grayStyle}
            placeholder={placeholder}
            className={classNames(
              'w-full text-blueberry sm:text-sm truncate',
              grayStyle
                ? 'border-0 focus:border-0 focus:outline-none focus:ring-0 font-semibold bg-silver group-hover:bg-silver-darker duration-150 rounded-3xl pl-3 pr-8'
                : `rounded-md border border-gray-300 bg-white shadow-sm pl-3 ${disableClear ? 'pr-10' : 'pr-20'}`
            )}
            displayValue={(item: IDropDownItem) => {
              if (!selectedItem) return '';

              if (useDisplayName) {
                return item?.displayName ?? item?.name ?? '';
              }
              return item?.name ? item.name : item?.title ? item.title : '';
            }}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-0 flex items-center justify-end pr-1">
            <div className="flex items-center pointer-events-auto">
              {loading ? (
                <AdjustableLoadingIcon width={5} height={5} />
              ) : !disableClear && selectedItem ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onClear) return onClear();
                    setSelectedItem(undefined);
                    setQuery('');
                  }}
                  className="p-1 hover:bg-gray-100 rounded-full cursor-pointer"
                  data-testid="clear-icon"
                >
                  <XMarkIcon className="h-4 w-4 text-blueberry" aria-hidden="true" />
                </div>
              ) : null}
              <div className="p-1 ml-1">
                {!grayStyle ? (
                  <ChevronUpDownIcon className="h-5 w-5 text-blueberry" aria-hidden="true" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4 text-blueberry" aria-hidden="true" />
                )}
              </div>
            </div>
          </Combobox.Button>
        </div>

        <Combobox.Options className={'text-blueberry'}>
          {filteredItems.length !== 0 ? (
            <VirtualizedList items={filteredItems} />
          ) : defaultOption && query !== '' ? (
            <div className="w-full absolute max-h-60 overflow-y-auto z-40 mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <Combobox.Option
                value={defaultValue}
                className={({ active }) =>
                  classNames(`duration-100 transition relative cursor-pointer select-none py-2 pl-2 pr-4  ${active ? 'bg-indigo-50' : 'text-gray-900'}`)
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={`flex flex-row line-clamp-3 items-center gap-x-2 ${selected ? 'font-medium' : 'font-normal'}`}> {query}</span>
                  </>
                )}
              </Combobox.Option>
            </div>
          ) : null}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

function VirtualizedList({ items }: { items: IDropDownItem[] }) {
  const parentRef = useRef<HTMLDivElement>(null);

  const count = items.length;
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
    overscan: 20,
  });

  const itemsToRender = virtualizer.getVirtualItems();

  return (
    <div className="" id="virtualized-list">
      <div
        ref={parentRef}
        className={
          'w-full absolute max-h-60 overflow-y-auto z-40 mt-1 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
        }
      >
        <div
          style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              transform: `translateY(${itemsToRender[0]?.start ?? 0}px)`,
            }}
            className="divide-y-2 divide-gray-100"
          >
            {itemsToRender.map((virtualRow) => (
              <div key={virtualRow.key} data-index={virtualRow.index} ref={virtualizer.measureElement}>
                <Combobox.Option
                  className={({ active }) =>
                    classNames(`duration-100 transition relative cursor-pointer select-none py-2 pl-2 pr-4 ${active ? 'bg-indigo-50' : 'text-gray-900'}`)
                  }
                  value={items?.[virtualRow.index]}
                >
                  {({ selected, active }) => (
                    <span id={`id-${virtualRow.key}`} className={`flex flex-row line-clamp-3 items-center gap-x-2 ${selected ? 'font-medium' : 'font-normal'}`}>
                      {items?.[virtualRow.index].displayName ?? items?.[virtualRow.index].name}
                    </span>
                  )}
                </Combobox.Option>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
