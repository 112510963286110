import { useEffect, useRef } from 'react';

interface DropdownProps {
    values: string[];
    filterString: string;
    onSelect: (value: string) => void;
    isOpen: boolean;
    onClose: () => void;
    id: string;
}

export const Dropdown = ({ values, filterString, onSelect, isOpen, onClose, id }: DropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose]);

    const filteredValues = values.filter(value => 
        filterString === '' || value.toLowerCase().includes(filterString.toLowerCase())
    );

    // Check if there's an exact match
    const hasExactMatch = values.some(value => 
        value.toLowerCase() === filterString.toLowerCase()
    );

    if (!isOpen) return null;

    return (
        <div 
            ref={dropdownRef}
            className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto"
            id={id}
        >
            <ul className="py-1">
                {filterString && !hasExactMatch && (
                    <li 
                        className="px-3 py-2 text-sm text-blueberry hover:bg-gray-100 cursor-pointer font-medium border-b border-gray-100"
                        onClick={() => {
                            onSelect(filterString);
                            onClose();
                        }}
                        id={`${id}-dropdown-item-0`}
                    >
                        Create: {filterString}
                    </li>
                )}
                {filteredValues.length > 0 ? (
                    filteredValues.map((value, index) => (
                        <li 
                            key={index}
                            className="px-3 py-2 text-sm text-blueberry hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                                onSelect(value);
                                onClose();
                            }}
                            id={`${id}-dropdown-item-${index + 1}`}
                        >
                            {value}
                        </li>
                    ))
                ) : filterString ? null : (
                    <li className="px-3 py-2 text-sm text-gray-500 italic">
                        No matches found
                    </li>
                )}
            </ul>
        </div>
    );
};

/**
 * @rows: Array of objects with path and sampleValues. eg:
 * [
 * {
 *  path: "path/to/value",
 *  sampleValues: ["sample1", "sample2", "sample3"]
 * }
 * ]
 * 
 * @filterString: The string to filter the rows by
 * @onSelect: The function to call when a row is selected
 * @isOpen: Whether the dropdown is open
 * @onClose: The function to call when the dropdown is closed
 * @id: The id of the dropdown
 */
interface TwoColumnDropdownProps {
    rows: Array<{
        path: string;
        sampleValues: string[];
    }>;
    filterString: string;
    onSelect: (value: string) => void;
    onSelectCustomField: () => void;
    isOpen: boolean;
    onClose: () => void;
    id: string;
}

export const TwoColumnDropdown = (props: TwoColumnDropdownProps) => {
    const hasExactMatch = props.rows.some(row => 
        row.path.toLowerCase().trim() === props.filterString.toLowerCase().trim()
    );

    const filteredRows = props.rows.filter(row => {
        const searchTerm = props.filterString.toLowerCase().trim();
        return row.path.toLowerCase().trim().includes(searchTerm) || 
            row.sampleValues.some(sample => 
                sample.toLowerCase().trim().includes(searchTerm)
            );
    });

    return (
        <div id={props.id} className={`absolute top-full left-0 w-full bg-white border border-gray-200 rounded-lg shadow-lg mt-1 z-10 ${props.isOpen ? '' : 'hidden'}`}>
            <div className="max-h-[30vh] sm:max-h-[35vh] overflow-y-auto">
                {props.filterString && !hasExactMatch && (
                    <div 
                        className="flex flex-row justify-between px-4 py-2 hover:bg-gray-50 cursor-pointer font-medium border-b border-gray-100"
                        onClick={props.onSelectCustomField}
                        id={`${props.id}-dropdown-item-0`}
                    >
                        <div className="w-1/2 text-blueberry font-mono break-words pr-4">Create: {props.filterString}</div>
                        <div className="w-1/2 text-gray-500 font-mono break-words italic">
                            "No sample values..."
                        </div>
                    </div>
                )}
                {filteredRows.length > 0 ? (
                    filteredRows.map((value, index) => (
                        <div 
                            key={index} 
                            className="flex flex-row justify-between px-4 py-2 hover:bg-gray-50 cursor-pointer"
                            onClick={() => props.onSelect(value.path)}
                            id={`${props.id}-dropdown-item-${index + 1}`}
                        >
                            <div className="w-1/2 text-blueberry font-mono break-words pr-4">{value.path}</div>
                            <div className="w-1/2 text-gray-500 font-mono break-words">
                                {value.sampleValues.slice(0, 3).map((sample, i) => (
                                    <span key={i}>
                                        "{sample}"
                                        {i < Math.min(2, value.sampleValues.length - 1) && ", "}
                                    </span>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="px-4 py-2 text-gray-500 italic text-center">
                        No custom field paths found
                    </div>
                )}
            </div>
        </div>
    );
};
