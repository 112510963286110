import { useContext, useEffect, useState, useRef } from 'react';
import {
  Channel_Type,
  DigestDataFragment,
  DigestStatus,
  GetDigestsDocument,
  GetInsightsDocument,
  GetInsightsQuery,
  InsightLightFragment,
  SearchDigestsDocument,
  useDeleteDigestMutation,
  useGenerateDraftDigestEmailMutation,
  useGetDigestSeriesChannelsQuery,
  usePublishDigestToSlackMutation,
  useSendTestDigestEmailMutation,
  useSendTestSlackDigestMutation,
  useSetDigestSubjectMutation,
  useSuggestInsightsMutation,
} from '../../../generated/graphql';
import Badge from '../Badge';
import { DigestDataContext, DigestDataDispatchContext } from '../../../context/digestDataContext';
import { CalendarDaysIcon, Cog6ToothIcon, EnvelopeIcon, TrashIcon } from '@heroicons/react/24/solid';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../../baseComponents/SettingsMenu';
import Tippy from '@tippyjs/react';
import { SparklesIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import InsightsListContainer, { InsightListContainerRef } from './insights/InsightListContainer';
import { DigestStatusActionButton, DigestStatusActions, DigestStatusBadge, getDigestStatus } from './DigestStatus';
import toast from 'react-hot-toast';
import UserContext from '../../../v2/contexts/UserContext';
import slackLogo from '../../../assets/slack-logo.png';
import YesCancelModal from '../Modals/YesCancelModal';
import { logError } from '../../../applicationTelemetry';
import { DigestDataActions, DigestObjectType } from '../../../reducers/digests/digestDataReducer';
import InlineEditableTextArea from '../../../baseComponents/InlineEditableTextArea';

const DigestMainSection = () => {
  const digestObject = useContext(DigestDataContext).selectedDigestObject as DigestDataFragment;
  const insightListRef = useRef<InsightListContainerRef>(null);

  return (
    <>
      <DigestHeader
        digest={digestObject}
        onSuggestInsightsComplete={() => {
          // Reenable pagination when suggestions are complete, so we can scroll and see the new insights
          insightListRef.current?.reenablePagination();
        }}
      />
      <div className="overflow-y-auto flex-auto min-h-0 mt-1 border-t-2 border-gray-200">
        <InsightsListContainer ref={insightListRef} digest={digestObject} />
      </div>
    </>
  );
};
interface ConfirmModalProps {
  text?: string;
  subtext?: string;
  confirmButton?: () => void;
  confirmText?: string;
}

interface DigestHeaderProps {
  digest: DigestDataFragment;
  onSuggestInsightsComplete: () => void;
}

const DigestHeader = ({ digest, onSuggestInsightsComplete }: DigestHeaderProps) => {
  // why is the user we get back from context nullable?
  const { user } = useContext(UserContext);
  const [digestStatusDisplay, setDigestStatusDisplay] = useState<DigestStatusActions>(getDigestStatus(digest));
  const digestData = useContext(DigestDataContext);
  const digestDataDispatch = useContext(DigestDataDispatchContext);
  const [suggestInsights] = useSuggestInsightsMutation();
  const [sendTestEmail] = useSendTestDigestEmailMutation();
  const [createDraftDigest] = useGenerateDraftDigestEmailMutation();
  const [sendTestSlack] = useSendTestSlackDigestMutation();
  const [publishDigestToSlack] = usePublishDigestToSlackMutation();
  const [deleteDigest] = useDeleteDigestMutation();
  const [confirmationModalOpen, setModalOpen] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState<ConfirmModalProps>({});
  const channels = useGetDigestSeriesChannelsQuery({ variables: { seriesId: digest.digestSeries.id } });
  const [setDigestSubject] = useSetDigestSubjectMutation();

  const digestHeaderOptions: ISettingsItem[] = [
    {
      name: 'Suggest Insights',
      id: 3,
      group: 'suggestion',
      htmlId: 'suggest-insights',
      icon: <SparklesIcon className="h-6 w-6" />,
      onClick: () => {
        const loadingId = toast.loading(`Generating Suggested Insights`, { duration: 30000 });
        suggestInsights({
          variables: {
            digestId: digest.id,
          },
          onCompleted: (result) => {
            if (result.suggestInsights) {
              toast.success(`Generated ${result.suggestInsights.length} Suggested Insights`);
              onSuggestInsightsComplete();
            }
          },
          onError: (e) => {
            logError(e);
            toast.error(`Error generating suggested insights: ${e.message}`);
          },
        }).finally(() => {
          toast.dismiss(loadingId);
        });
      },
    },
    {
      name: 'Send Test Email',
      id: 4,
      group: 'sending-email',
      htmlId: 'send-test-email',
      icon: <EnvelopeIcon className="h-6 w-6" />,
      onClick: () => {
        const loadingId = toast.loading(`Sending Email to ${user!.email}`, { duration: 30000 });
        sendTestEmail({ variables: { digestId: digest.id, emailAddress: user!.email } })
          .then((result) => {
            if (result.data?.sendTestDigestEmail.success) {
              toast.success(`Test email sent to ${user!.email}`);
            } else {
              toast.error(`Error sending test email: ${result.data?.sendTestDigestEmail.message}`);
            }
          })
          .finally(() => {
            toast.dismiss(loadingId);
          });
      },
    },
    {
      name: 'Create Draft Email',
      id: 5,
      group: 'sending-email',
      htmlId: 'send-draft-email',
      icon: <EnvelopeIcon className="h-6 w-6" />,
      onClick: () => {
        const loadingId = toast.loading(`Preparing draft email`, { duration: 30000 });
        createDraftDigest({ variables: { digestId: digest.id } })
          .then((result) => {
            if (result.data?.generateDraftDigestEmail.success) {
              toast.success('Draft email created');
            } else {
              toast.error(`Error creating draft email: ${result.data?.generateDraftDigestEmail.message}`);
            }
          })
          .finally(() => {
            toast.dismiss(loadingId);
          });
      },
    },
    {
      name: 'Send Test Slack Message',
      id: 6,
      group: 'sending-slack',
      htmlId: 'send-test-slack',
      icon: <img src={slackLogo} className={'h-4 w-4'} alt="Slack Logo" />,
      onClick: () => {
        setModalOpen(true);
        setConfirmModalProps({
          text: 'Are you sure you want to send a test slack message?',
          confirmButton: () => sendTestSlackMessageCallback(),
          subtext: 'This will send a test slack message to the channel: #digest-slack-test In the Unwrap workspace.',
        });
      },
    },
    {
      name: 'Publish Digest to Slack',
      id: 6,
      group: 'sending-slack',
      htmlId: 'publish-slack',
      icon: <img src={slackLogo} className={'h-4 w-4'} alt="Slack Logo" />,

      onClick: () => {
        setModalOpen(true);

        const slackChannels = channels.data?.digestSeriesChannels.filter((chan) => chan.subChannelType === Channel_Type.Slack);
        if (!slackChannels || slackChannels.length === 0) {
          setConfirmModalProps({
            text: "This Digest Series doesn't have any slack channels configured.",
            confirmButton: () => {},
            subtext: `Go to the Digest Series Page and configure a slack channel to send this digest to.`,
            confirmText: 'Ok :(',
          });
        } else {
          setConfirmModalProps({
            text: `Are you sure you want to publish this digest to ${digest.digestSeries.organization.name}'s slack workspace?`,
            confirmButton: () => publishDigestToSlackCallback(),
            subtext: `This will send a slack message to the channel: ${slackChannels?.map((ch) => ch.subChannelName)}. In ${
              digest.digestSeries.organization.name
            }'s slack workspace.`,
          });
        }
      },
    },
    {
      name: 'Delete Digest',
      id: 7,
      group: 'digest-config',
      htmlId: 'delete-digest',
      icon: <TrashIcon className="h-6 w-6" />,
      onClick: () => {
        const deletableStatuses: DigestStatus[] = [DigestStatus.Draft, DigestStatus.PendingReview];
        if (!deletableStatuses.includes(digest.status)) {
          toast.error(`You can only delete digests with the following statuses: ${deletableStatuses.join(', ')}`);
          return;
        }
        setModalOpen(true);
        setConfirmModalProps({
          text: 'Are you sure you want to delete this digest?',
          confirmButton: () => deleteDigestCallback(),
          subtext: 'This will delete the digest and all of its insights.',
          confirmText: 'Delete',
        });
      },
    },
  ];

  const deleteDigestCallback = async () => {
    const loadingId = toast.loading(`Deleting digest`, { duration: 30000 });
    try {
      const result = await deleteDigest({
        variables: { digestId: digest.id },
      });
      if (result.data?.deleteDigest.id) {
        toast.success('Digest deleted');
        digestDataDispatch?.({
          type: DigestDataActions.SetSelectedDigestObject,
          payload: {
            selectedObject: digestData.digests.filter((dig) => dig.status === DigestStatus.Draft && dig.id !== digest.id)[0],
            digestObjectType: DigestObjectType.Digest,
          },
        });
        digestDataDispatch?.({
          type: DigestDataActions.SetDigestData,
          payload: {
            digests: digestData.digests.filter((dig) => dig.id !== digest.id),
          },
        });
      } else toast.error(`Error deleting digest`);
    } catch (e: any) {
      logError(e);
      console.error(`Error deleting digest: ${e}`);
    }
    toast.dismiss(loadingId);
  };

  const sendTestSlackMessageCallback = async () => {
    const loadingId = toast.loading(`Sending slack message`, { duration: 30000 });
    try {
      const result = await sendTestSlack({ variables: { digestId: digest.id } });
      if (result.data?.sendTestSlack.success) {
        toast.success('Sent slack message: ' + result.data.sendTestSlack.message);
      } else {
        toast.error(`Error sending slack message: ${result.data?.sendTestSlack.message}`);
      }
    } catch (e: any) {
      logError(e);
      toast.error(`Error sending slack message: ${e.message}`);
    }
    toast.dismiss(loadingId);
  };

  const publishDigestToSlackCallback = async () => {
    const loadingId = toast.loading(`Sending slack message`, { duration: 30000 });
    try {
      const result = await publishDigestToSlack({ variables: { digestId: digest.id } });
      if (result.data?.sendDigestToSlack.success) {
        toast.success('Sent slack message: ' + result.data.sendDigestToSlack.message);
      } else {
        toast.error(`Error sending slack message: ${result.data?.sendDigestToSlack.message}`);
      }
    } catch (e: any) {
      logError(e);
      toast.error(`Error sending slack message: ${e.message}`);
    }
    toast.dismiss(loadingId);
  };

  const updateDigestSubject = async (subject: string) => {
    const loadingId = toast.loading(`Updating digest subject`, { duration: 30000 });
    try {
      const result = await setDigestSubject({ variables: { digestId: digest.id, subject } });
      if (result.data?.setDigestSubject.id) {
        toast.success('Digest subject updated');
        digestDataDispatch?.({ type: DigestDataActions.SetDigestSubject, payload: { subject } });
      } else {
        toast.error(`Error updating digest subject`);
      }
    } catch (e: any) {
      logError(e);
      toast.error(`Error updating digest subject: ${e.message}`);
    }
    toast.dismiss(loadingId);
  };

  useEffect(() => {
    setDigestStatusDisplay(getDigestStatus(digest));
  }, [digest.id, digest.status]);

  return (
    <div className="flex flex-col gap-y-2 bg-milk text-blueberry ">
      <YesCancelModal
        modalOpen={confirmationModalOpen}
        callbackModal={setModalOpen}
        confirmButton={confirmModalProps.confirmButton}
        text={confirmModalProps.text}
        subtext={confirmModalProps.subtext}
        confirmText={confirmModalProps.confirmText ?? 'Ship this bitch!'}
        closeOnConfirm={true}
      />
      {/* Title section */}
      <div className="flex flex-row justify-between items-center ">
        <div className="flex flex-row items-center gap-x-4">
          <h1 className="text-3xl font-bold">{digest.digestSeries.title}</h1>
          <DigestStatusBadge actions={digestStatusDisplay} />
          {/* <Badge badge={{ text: digest.status, id: '0' }} smaller={true} color="bg-lemonhead" /> */}
        </div>
        <div className="flex flex-row gap-x-4">
          <DigestStatusActionButton actions={digestStatusDisplay} />

          {/* change status 'approved' -> 'reject' / 'draft' -> 'approve' | 'reject' */}
          <SettingsMenu settings={digestHeaderOptions} center={true} align={MenuAlign.RIGHT}>
            <Tippy theme="light" delay={200} content={<p className="text-center">Options</p>}>
              <Cog6ToothIcon className=" h-8 w-8 z-10" />
            </Tippy>
          </SettingsMenu>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <InlineEditableTextArea
          ignorePadding
          textAreaId="digest-subject-text-area"
          value={digest.subject ?? ''}
          editable={true}
          preventEmpty={false}
          placeholder="Subject Line (optional)"
          onEditComplete={(value) => updateDigestSubject(value)}
          additionalClassNames={
            'text-xl xl:text-2xl  h-auto font-semibold focus:border-none hover:bg-silver focus:bg-silver bg-transparent transition-[background-color] duration-100 rounded-md '
          }
        />
      </div>
      <div className="flex flex-row text-md font-normal text-gray-400 gap-x-4 items-center">
        <div>
          {digest.digestSeries.organization.name} ({digest.digestSeries.organization.id})
        </div>
        <div className="flex flex-row gap-x-1 items-center">
          <UserCircleIcon className="h-5 w-5" />
          <p className="text-xs">
            {digest.creator.firstName} {digest.creator.lastName}
          </p>
        </div>
        <div className="flex flex-row gap-x-1 items-center">
          <CalendarDaysIcon className="h-5 w-5" />
          <p className="text-xs">{new Date(digest.dateCreated).toDateString()}</p>
        </div>
      </div>
      <div className="flex flex-row gap-x-1 flex-wrap gap-y-1">
        {digest.digestSeries.teams.map((team) => {
          return <Badge key={team.id} badge={{ text: `${team.name} (${team.id})`, id: team.id.toString() }} smaller={true} />;
        })}
      </div>
    </div>
  );
};

export default DigestMainSection;
