import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { FilterSources } from './FilterSources';

export const FilterRow = ({
  selected,
  filterName,
  filterType,
  onClick,
  sources,
  isAIGenerated = false,
}: {
  selected: boolean;
  filterName: string;
  filterType: string;
  onClick: () => void;
  sources?: string[];
  isAIGenerated?: boolean;
}) => {
  return (
    <div
      id={`filter-row-${filterType}`}
      className={`
    flex flex-col 
    cursor-pointer 
    hover:bg-gray-100 
    w-full px-4 py-2 
    transition-all duration-200
    ${selected ? 'bg-gray-100' : ''}
  `}
      onClick={onClick}
      role="button"
      aria-selected={selected}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <div className="text-blueberry-light font-semibold">{filterName}</div>
        </div>
        <div className="text-gray-500">
          <ChevronRightIcon className="w-4 h-4 mt-1" />
        </div>
      </div>
      <FilterSources sources={sources} isAIGenerated={isAIGenerated} justify="start" />
    </div>
  );
};
