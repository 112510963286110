import { useContext, useEffect, useState, useMemo } from 'react';
import { DigestStatus, useSearchDigestsQuery, useSearchDigestSeriesQuery } from '../../../generated/graphql';
import { DigestDataContext, DigestDataDispatchContext } from '../../../context/digestDataContext';
import { DigestDataActions, DigestObjectType } from '../../../reducers/digests/digestDataReducer';
import CreateDigestSeries from './CreateDigestSeries';
import CreateDigest from './CreateDigest';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import DigestDigestSeriesListChooser from './DigestDigestSeriesListChooser';
import DigestListItem from './DigestListItem';
import DigestSeriesListItem from './DigestSeriesListItem';
import SearchInput from '../../baseComponents/SearchInput';
import LoadingSpinner from '../../baseComponents/LoadingSpinner';
import { debounce } from 'lodash';
import { LoadingDots } from '../filters/filterBar/DateFilterComponent';

const DigestSideBarSection = () => {
  const dispatchDigestData = useContext(DigestDataDispatchContext);
  const {
    data: digestData,
    loading: digestLoading,
    refetch: refetchDigest,
  } = useSearchDigestsQuery({
    variables: { query: '' },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: digestSeriesData,
    loading: digestSeriesLoading,
    refetch: refetchDigestSeries,
  } = useSearchDigestSeriesQuery({
    variables: { query: '' },
    notifyOnNetworkStatusChange: true,
  });
  const [createDigestSeriesModalOpen, setCreateDigestSeriesModalOpen] = useState(false);
  const [createDigestModalOpen, setCreateDigestModalOpen] = useState(false);
  const [chosenDorDsSelector, setChosenDorDsSelector] = useState<DigestObjectType>(DigestObjectType.Digest);
  const [digestSearchQuery, setDigestSearchQuery] = useState('');
  const [digestSeriesSearchQuery, setDigestSeriesSearchQuery] = useState('');

  const debouncedSearch = useMemo(() => {
    return debounce((query: string) => {
      if (chosenDorDsSelector === DigestObjectType.Digest) {
        refetchDigest({ query });
      } else {
        refetchDigestSeries({ query });
      }
    }, 300);
  }, [refetchDigestSeries, refetchDigest, chosenDorDsSelector]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    if (digestSeriesData?.searchDigestSeries) {
      dispatchDigestData?.({ type: DigestDataActions.SetDigestSeriesData, payload: { digestSeriesData: digestSeriesData?.searchDigestSeries } });
    }
  }, [digestSeriesData]);

  useEffect(() => {
    if (digestData?.searchDigests) {
      dispatchDigestData?.({ type: DigestDataActions.SetDigestData, payload: { digests: digestData.searchDigests } });
    }
  }, [digestData]);

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  const handleCreateButtonClick = () => {
    if (chosenDorDsSelector === DigestObjectType.Digest) {
      setCreateDigestModalOpen(true);
    } else {
      setCreateDigestSeriesModalOpen(true);
    }
  };

  return (
    <div className="flex flex-col gap-y-2 h-screen bg-milk">
      {/* Modals for creating digest and digest series */}
      <CreateDigestSeries modalOpen={createDigestSeriesModalOpen} setFalseToCloseModal={setCreateDigestSeriesModalOpen} />
      <CreateDigest
        modalOpen={createDigestModalOpen}
        setFalseToCloseModal={(open) => {
          setCreateDigestModalOpen(open);
          if (!open) setDigestSearchQuery('');
        }}
      />
      <Button expandWidth={true} id="create-digest" variant={ButtonVariant.Primary} text="Create New" onClick={handleCreateButtonClick} />
      <DigestDigestSeriesListChooser chosenState={chosenDorDsSelector} setChoseState={setChosenDorDsSelector} />
      <SearchInput
        noPadding
        placeholder="Search..."
        queryString={chosenDorDsSelector === DigestObjectType.Digest ? digestSearchQuery : digestSeriesSearchQuery}
        setQueryString={(string) =>
          chosenDorDsSelector === DigestObjectType.Digest ? setDigestSearchQuery(string ?? '') : setDigestSeriesSearchQuery(string ?? '')
        }
        onSearch={() => {}}
        onChange={(e) => handleSearchChange(e)}
        onClear={() => debouncedSearch('')}
      />
      {chosenDorDsSelector === DigestObjectType.Series ? <DigestSeriesList loading={digestSeriesLoading} /> : <DigestList loading={digestLoading} />}
    </div>
  );
};

const DigestList = ({ loading }: { loading: boolean }) => {
  const digestData = useContext(DigestDataContext);
  if (loading) {
    return (
      <div className="flex items-center justify-center mt-12">
        <LoadingSpinner />
      </div>
    );
  }
  return digestData.digests.length > 0 ? (
    <div className="flex flex-col overflow-y-auto">
      {[DigestStatus.Draft, DigestStatus.PendingReview, DigestStatus.Approved, DigestStatus.Sent].map((status: DigestStatus) =>
        digestData.digests
          .filter((digest) => digest.status === status)
          .map((digest) => {
            return <DigestListItem key={digest.id} item={digest} />;
          })
      )}
    </div>
  ) : (
    <div className="text-center text-gray-500">No digests found</div>
  );
};

const DigestSeriesList = ({ loading }: { loading: boolean }) => {
  const digestData = useContext(DigestDataContext);
  if (loading) {
    return (
      <div className="flex items-center justify-center mt-12">
        <LoadingSpinner />
      </div>
    );
  }
  return digestData.digestSeriesData.length > 0 ? (
    <div className="flex flex-col overflow-y-auto">
      {digestData.digestSeriesData.map((digestSeriesItem) => {
        return <DigestSeriesListItem item={digestSeriesItem} />;
      })}
    </div>
  ) : (
    <div className="text-center text-gray-500">No digest series found</div>
  );
};

export default DigestSideBarSection;
