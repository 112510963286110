import { AlterStaticFiltersPayload, FilterState, FilterStatementSchema } from '../../../reducers/filterStatement/filterStatementReducer';

export type DateRangeOption = {
  label: string;
  getDates: () => { start?: Date; end?: Date };
};

export type DateRangePayload = {
  start?: Date;
  end?: Date;
};

export class DateFilterUtility {
  static readonly fieldName = 'Entry.date';

  private static setToEndOfDay(date: Date): Date {
    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  }

  private static setToStartOfDay(date: Date): Date {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0);
    return startOfDay;
  }

  static getStartDate(filterState: FilterState): Date | undefined {
    const dateFilters = filterState.staticConditions?.filter((node) => node.type === 'statement' && node.fieldName === this.fieldName);
    const startFilter = dateFilters?.find((filter) => filter.operator === '>=');
    return startFilter ? this.setToStartOfDay(new Date(startFilter.value)) : undefined;
  }

  static getEndDate(filterState: FilterState): Date | undefined {
    const dateFilters = filterState.staticConditions?.filter((node) => node.type === 'statement' && node.fieldName === this.fieldName);
    const endFilter = dateFilters?.find((filter) => filter.operator === '<=');
    return endFilter ? this.setToEndOfDay(new Date(endFilter.value)) : undefined;
  }

  static getSelectedOption(filterState: FilterState, dateRangeOptions: DateRangeOption[]): DateRangeOption {
    const dateFilters = filterState.staticConditions?.filter((node) => node.type === 'statement' && node.fieldName === this.fieldName);

    if (!dateFilters || dateFilters.length === 0) {
      return dateRangeOptions.find((option) => option.label === 'All')!;
    }

    const startFilter = dateFilters.find((filter) => filter.operator === '>=');
    const endFilter = dateFilters.find((filter) => filter.operator === '<=');

    if (!startFilter) {
      return dateRangeOptions.find((option) => option.label === 'All')!;
    }

    const filterStart = new Date(startFilter.value);
    const filterEnd = endFilter ? new Date(endFilter.value) : undefined;

    // Check each predefined option
    for (const option of dateRangeOptions) {
      const { start: optionStart, end: optionEnd } = option.getDates();

      if (!optionEnd && filterEnd) {
        continue;
      }

      // Check if dates are within 24 hours of each other
      if (!optionStart) continue;

      const startDiff = Math.abs(filterStart.getTime() - optionStart.getTime());
      const endDiff = filterEnd && optionEnd ? Math.abs(filterEnd.getTime() - optionEnd.getTime()) : undefined;
      if (startDiff <= 24 * 60 * 60 * 1000 && ((!endDiff && !optionEnd) || (endDiff && endDiff <= 24 * 60 * 60 * 1000))) {
        return option;
      }
    }

    // If no match found, return custom option
    return {
      label: 'Custom',
      getDates: () => ({
        start: filterStart,
        end: filterEnd,
      }),
    };
  }

  static getModifyDateFiltersPayload(dates: DateRangePayload): AlterStaticFiltersPayload {
    const filterNodes: FilterStatementSchema[] = [];

    if (dates.start) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '>=',
        value: this.setToStartOfDay(dates.start).toISOString(),
      });
    }

    if (dates.end) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '<=',
        value: this.setToEndOfDay(dates.end).toISOString(),
      });
    }

    return {
      upsert: {
        filterNodes,
      },
      remove: {
        fieldNames: [],
      },
    };
  }

  static getDateFilterStatement(dates: DateRangePayload): FilterStatementSchema[] {
    const filterNodes: FilterStatementSchema[] = [];
    if (dates.start) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '>=',
        value: this.setToStartOfDay(dates.start).toISOString(),
      });
    }

    if (dates.end) {
      filterNodes.push({
        type: 'statement',
        fieldName: this.fieldName,
        operator: '<=',
        value: this.setToEndOfDay(dates.end).toISOString(),
      });
    }

    return filterNodes;
  }

  /**
   * This clears any date filters and replaces them with the new dates
   * @param dates
   * @returns
   */
  static getReplaceDateFiltersPayload(dates: DateRangePayload): AlterStaticFiltersPayload {
    const filterNodes: FilterStatementSchema[] = this.getDateFilterStatement(dates);

    return {
      upsert: {
        filterNodes,
      },
      remove: {
        fieldNames: [this.fieldName],
      },
    };
  }
}
