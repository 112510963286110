import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import { Group_Status, useAmountOfGroupsLazyQuery, useTeamGroupsTaxonomyFlatLazyQuery } from '../../generated/graphql';
import { AppRoutes } from '../../Routes';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { GroupFull } from '../../v2/hooks/GroupHook';
import LoadingSpinner from '../baseComponents/LoadingSpinner';
import GroupPreview from '../components/GroupPreview';
import TrendingNewButton from '../components/Search/TrendingNewButton';
import { GroupCount } from '../components/taxonomy/GroupCount';
import { useExploreGroupHook } from '../hooks/ExploreGroupHook';
import { useFilterHook } from '../hooks/FilterHook';
import { getGroupPageUrl } from '../lib/groups';
import { DenominatorOptions, denominatorOptionsDropdown } from '../sections/Filters/FiltersTypes';
import { FilterManagerDisplayMode } from '../sections/Filters/FiltersUtil';
import { FilterManager } from '../sections/Filters/ManagerV2/FilterManager';
import { PageWrapper } from './PageWrapper';
import { toast } from 'react-hot-toast';
import { InitialFilterStateProvider } from '../filters/utilities/InitialFilterStateProvider';
import { FilterNode } from '../lib/filterNode';
import { getGroupLink } from '../components/groups/toolbar/buttons/CopyGroupUrlButton';

const PAGE_SIZE = 20;

export const GroupListPage = () => {
  const PAGE_NAME = 'Group List';
  const navigate = useNavigate();
  const location = useLocation();
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();

  const urlSearchParams = new URLSearchParams(location.search);
  const expanded = urlSearchParams.get('expanded');

  const filterHook = useFilterHook({ teamId, orgId, disableGroupFilterFromUrl: expanded === 'drawer' });

  const groupHook = useExploreGroupHook({
    teamId,
    orgId,
    teamName: currentTeam?.name,
    orgName: currentOrg?.name,
    pageName: 'group-list',
    filterInput: filterHook.filters,
    status: Group_Status.Monitored,
    selectedDenominator: denominatorOptionsDropdown.find((d) => d.name === DenominatorOptions.DateFilteredFeedback),
    pageSize: PAGE_SIZE,
    sentencesTake: 0,
  });

  const {
    groups,
    loadAllSentences,
    loadingStatuses,
    loadMore,
    currentGroup,
    setCurrentGroup,
    replaceOrAddToSearchGroups,
    updateProgress,
    discardGroup,
    copyGroupLink,
    editTitle,
    togglePinGroup,
    loadListView,
  } = groupHook;

  const [paginating, setPaginating] = useState(false);
  const { ref, inView } = useInView({ threshold: 0 });

  const [groupsFetched, setGroupsFetched] = useState(1);

  const loadMoreGroups = async () => {
    setPaginating(true);
    const newCount = await loadMore(groups.length, PAGE_SIZE);
    setPaginating(false);
    setGroupsFetched(newCount);
  };


  useEffect(() => {
    if (inView && !paginating && groupsFetched > 0) {
      loadMoreGroups();
    }
  }, [inView, paginating]);

  const [loadTaxonomyFlat, { loading: loadingFlat }] = useTeamGroupsTaxonomyFlatLazyQuery({
    variables: { teamId, filterInput: filterHook.filters ?? {}, take: 1, skip: 0 },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setFilteredGroupCount(data.teamGroups?.amountOfGroups.amount ?? 0);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const [filteredGroupCount, setFilteredGroupCount] = useState<number>(0);

  const handleAnnouncementClick = (groupId: string) => {
    // Handle announcement modal - could be moved to a separate context if needed
  };

  const [getAmountOfGroups, amountOfGroups] = useAmountOfGroupsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { teamId: teamId, status: Group_Status.Monitored },
  });

  useEffect(() => {
    getAmountOfGroups();
    loadTaxonomyFlat();
  }, [teamId]);

  useEffect(() => {
    loadListView(teamId, filterHook.filters);
  }, [teamId, filterHook.filters]);

  return (
    <PageWrapper title={'Group List'} styles={`${window.location.pathname.includes('/group/') && 'hidden'}`}>
      <div className="flex cursor-default relative flex-col text-blueberry mb-2">
        <div className="absolute -bottom-4">
          <GroupCount
            loading={loadingStatuses.fetchingMoreGroups}
            filteredGroupCount={filteredGroupCount}
            amountOfGroups={amountOfGroups.data?.amountOfGroups.amount}
          />
        </div>
      </div>
      <div className="mt-4 flex flex-col items-center justify-center">
        <div className="w-4/5 border-b border-gray-300 pb-2 flex flex-row justify-between items-start">
          <FilterManager
            filterHook={filterHook}
            pageName={PAGE_NAME}
            dataTypeToFilter={'group'}
            queryStringAppliesToGroupTitle={true}
            displayMode={FilterManagerDisplayMode.ExplorePage}
          />
          <div className=" flex flex-row items-center gap-x-2 justify-end">
            <TrendingNewButton filterHook={filterHook} />
          </div>
        </div>
        <div className={`mt-2 flex w-4/5 flex-col items-center gap-y-4 pt-2`}>
          <div className="mt-2 flex w-full flex-col items-center gap-y-4 pt-2">
            {!paginating && loadingStatuses.fetchingGroups ? (
              <LoadingSpinner />
            ) : (
              <>
                {groups.length === 0 ? (
                  <div className="my-8 text-blueberry">
                    <h1>No search groups match the selected filters.</h1>
                  </div>
                ) : (
                  <>
                    {groups.map((group, index) => {
                      const show = index === groups.length - 2;
                      return (
                        // This is the list view?? Can we kill this shit?
                        <GroupPreview
                          refProp={show ? ref : undefined}
                          isCondensedView={false}
                          key={group.id}
                          group={group as GroupFull}
                          filterInput={filterHook.filters}
                          page={PAGE_NAME}
                          discardSearchGroup={() => {
                            // setCurGroupIdToDelete(group.id);
                          }}
                          togglePinGroup={togglePinGroup}
                          replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
                          getAllGroupSentences={() => loadAllSentences(group.id)}
                          loadingAllSentences={loadingStatuses.loadingAllSentences}
                          updateProgress={updateProgress}
                          copyLink={async (groupId) => {
                            const filterState = InitialFilterStateProvider.parseOldFilterContractFromUrlParams(JSON.stringify(filterHook.filters), teamId);
                            console.log(filterState);
                            const filterNode = new FilterNode(filterState);
                            const link = getGroupLink(groupId, filterNode, teamId, orgId);
                            await navigator.clipboard.writeText(link);
                          }}
                          onSeeMoreClick={() => {
                            if (currentGroup?.id !== group.id) {
                              setCurrentGroup(group);
                            }
                            navigate(getGroupPageUrl(teamId, orgId, group.id));
                          }}
                          editTitle={editTitle}
                          openAnnouncementModal={() => {}}
                        />
                      );
                    })}
                    {loadingStatuses.fetchingMoreGroups && (
                      <div>
                        <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
