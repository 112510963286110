import { FilterRow } from './FilterRow';
import { FilterField } from '../../../../generated/graphql';
import { useState } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

export const FieldDropdown = ({
  fields,
  setSelectedField,
  selectedField,
}: {
  fields: FilterField[];
  setSelectedField: (field: FilterField) => void;
  selectedField: FilterField | null;
}) => {
  const [query, setQuery] = useState('');
  const queriedFields = query === '' ? fields : fields.filter((field) => field.displayName.toLowerCase().includes(query.toLowerCase()));
  return (
    <div className="flex flex-col max-h-[400px] overflow-y-scroll">
      <div className="relative">
        <div className="px-4 py-3 bg-blueberry-light/10 rounded-md">
          <div className="absolute inset-y-0 right-0 pr-6 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-blueberry" />
          </div>
          <input
            id="field-search"
            className="block w-full rounded-md bg-white border border-gray-200 pr-10 pl-3 text-sm placeholder:text-gray-500 focus:outline-none shadow-sm focus:border-blueberry"
            data-testid="search-input"
            placeholder="Search filter fields..."
            type="text"
            name="search"
            autoComplete="off"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
      </div>
      <section className="flex-col gap-2">
        {queriedFields
          .filter((field) => field.hasOverLapWithExistingFilters)
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .map((field, idx) => (
            <FilterRow
              key={idx}
              selected={selectedField?.fieldName === field.fieldName}
              filterName={field.displayName}
              filterType={field.fieldName}
              sources={field.additionalMetaData}
              isAIGenerated={field.subTitle == 'AI Generated'}
              onClick={() => {
                setSelectedField(field);
              }}
            />
          ))}
      </section>
    </div>
  );
};
