import { useState } from "react";
import Button, { ButtonShape, ButtonVariant } from "../../baseComponents/Button";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { FeedbackIntegrationActionType } from "../../actions/feedbackIntegration";
import { FeedbackIntegrationContext, FeedbackIntegrationDispatchContext } from "../../context/FeedbackIntegrationContext";
import LoadingSpinner from "../../baseComponents/LoadingSpinner";
import toast from "react-hot-toast";
import { FeedbackIntegrationState } from "../../reducers/feedbackIntegration";
import { Dropdown, TwoColumnDropdown } from "./Dropdown";

interface AddCustomFieldsModalProps {
    onClose: () => void;
}

export const AddCustomFieldsModal = (props: AddCustomFieldsModalProps) => {
    const [isDisplayDropdownOpen, setIsDisplayDropdownOpen] = useState(false);
    const [isCustomFieldDropdownOpen, setIsCustomFieldDropdownOpen] = useState(false);
    const [customFieldDisplayName, setCustomFieldDisplayName] = useState("");
    const [customField, setCustomField] = useState("");

    const activateAddButton = customFieldDisplayName && customField;

    const data = useContext(FeedbackIntegrationContext);
    const dispatch = useContext(FeedbackIntegrationDispatchContext);

    const selectValue = (value: string) => {
        setIsCustomFieldDropdownOpen(false);
        setCustomField(value);
    }

    const selectCustomField = () => {
        selectValue(customField);
    }

    return (
        <>
            <div 
                className="fixed inset-0 z-[100]"
                onClick={props.onClose}
            />
            <div className="relative" id="add-custom-fields-modal">
                <div className="absolute right-0 max-w-[calc(100vw-20px)] w-[400px] md:w-[600px] translate-x-[-10px] bg-white border border-gray-200 shadow-lg rounded-xl z-[101]">
                    {data.sourceFieldsLoading ? (
                        <div className="p-4 text-center">
                            <LoadingSpinner />
                            <div className="mt-2">Loading available fields...</div>
                        </div>
                    ) : (
                        <div className="p-4">
                            {!data.dataSourceFields?.length ?? 
                            toast.error("Unable to load sample data. You can still configure custom fields.")}
                            <div className="mb-4">
                                <label className="text-sm text-gray-500 mb-1">Custom field</label>
                                <div className="relative">
                                    <input 
                                        type="text" 
                                        onClick={() => setIsCustomFieldDropdownOpen(true)}
                                        onChange={(e) => {
                                            setCustomField(e.target.value);
                                            setIsCustomFieldDropdownOpen(true);
                                        }}
                                        value={customField}
                                        placeholder="Select a custom field" 
                                        className="rounded-md border border-gray-300 shadow-sm px-2 pt-1.5 pb-2 h-10 text-blueberry placeholder-gray-400 focus:outline-1 focus:outline-blueberry disabled:bg-gray-200 disabled:text-gray-500 w-full cursor-pointer" 
                                        id="add-custom-fields-modal-custom-field"
                                        autoComplete="off"
                                    /> 
                                    <TwoColumnDropdown 
                                        rows={data.dataSourceFields?.map(field => ({
                                            path: field.path,
                                            sampleValues: field.values || []
                                        })) || []}
                                        filterString={customField} 
                                        onSelect={selectValue} 
                                        onSelectCustomField={selectCustomField}
                                        isOpen={isCustomFieldDropdownOpen} 
                                        onClose={() => {
                                            setIsCustomFieldDropdownOpen(false);
                                        }}
                                        id="add-custom-fields-modal-custom-field-dropdown"
                                    />
                                </div>
                            </div>
                            <div className="mb-1">
                                <label htmlFor="" className="text-sm text-gray-500 mb-1">Display name</label>
                                <div className="relative">
                                    <input 
                                        type="text" 
                                        onSelect={() => {setIsDisplayDropdownOpen(true)}} 
                                        onChange={(e) => {setCustomFieldDisplayName(e.target.value)}} 
                                        value={customFieldDisplayName} 
                                        placeholder="Type to create a new name" 
                                        className="rounded-md border  border-gray-300 shadow-sm px-2 pt-1.5 pb-2 h-10  text-blueberry  placeholder-gray-400 focus:outline-1 focus:outline-blueberry disabled:bg-gray-200 disabled:text-gray-500 w-full"
                                        disabled={!customField}
                                        id="add-custom-fields-modal-display-name"
                                        autoComplete="off"
                                    />    
                                    <Dropdown 
                                        values={data.segmentGroups.segments?.map(segment => segment.displayName) || []} 
                                        filterString={customFieldDisplayName} 
                                        onSelect={(value) => {
                                            setIsDisplayDropdownOpen(false);
                                            setCustomFieldDisplayName(value);
                                        }} 
                                        isOpen={isDisplayDropdownOpen} 
                                        onClose={() => {setIsDisplayDropdownOpen(false)}}
                                        id="add-custom-fields-modal-display-name-dropdown"
                                    />
                                </div>
                            </div>
                            
                            <div className="mb-2">
                                <label htmlFor="" className="text-sm text-gray-500 italic mb-1">Example value</label>
                                <div className="font-mono bg-blueberry_lighter bg-opacity-10 rounded-md py-2 px-2 text-sm text-blueberry break-words">
                                    {customField ? `${customField}: "${data.dataSourceFields?.find(field => field.path === customField)?.values[0] || "No sample value available"}"` : "Select a custom field"}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="border-t-2 border-gray-200 py-6 px-8 lg:px-14 flex flex-col lg:flex-row gap-4 items-center justify-center">
                        <Button 
                            text="Add" 
                            shape={ButtonShape.Pill} 
                            icon={<CheckCircleIcon className="h-6 w-6" />} 
                            iconPosition={"left"} 
                            variant={ButtonVariant.Primary} 
                            onClick={() => {
                                const customFieldExists = checkIfCustomFieldExists(customField, customFieldDisplayName, data.feedbackIntegration!);
                                if (customFieldExists) {
                                    toast.error("Custom field already exists");
                                    return;
                                }
                                let newCustomField = createCustomField(
                                    customFieldDisplayName, 
                                    customField
                                );
                                dispatch({
                                    type: FeedbackIntegrationActionType.AddSegments,
                                    payload: {
                                        segment: newCustomField
                                    }
                                });
                                props.onClose();
                            }} 
                            disabled={!activateAddButton}
                            id="add-custom-fields-modal-add-button"
                        />                            
                    </div>
                </div>
            </div>
        </>
    );
}

const checkIfCustomFieldExists = (customField: string, customFieldDisplayName: string, feedbackIntegration: FeedbackIntegrationState) => {
    return feedbackIntegration.segmentConfig.find(config => config.path === customField && config.segmentGroup.displayName === customFieldDisplayName);
}

const createCustomField = (customFieldDisplayName: string, customField: string) => {
    // Create new segment config with negative IDs
    // Ids will be replaced in the context reducer
    const newSegmentConfig = {
        path: customField,
        segmentGroup: {
            id: -1,
            displayName: customFieldDisplayName,
        },
        id: -1,
    };

    return newSegmentConfig;
}