export interface ILowKeyTagProps {
  text: string;
}

export const LowKeyTag = ({ text }: ILowKeyTagProps) => {
  return (
    <div className="flex flex-row justify-center items-center text-gray-500 font-light text-xxs px-1 pt-0 pb-0 rounded-[4px] border-gray-200 border-[1px] bg-silver">
      {text}
    </div>
  );
};
