import { useContext, useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { DocumentTextIcon, ExclamationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import Modal from '../../baseComponents/Modal';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import PreviewTable from '../Tables/PreviewTable';
import TooltipIcon from '../../../v2/components/Modals/TooltipIcon';
import SelectOrDragFile from '../FileUploaders/SelectOrDragFile';
import { useCSVHook } from '../../hooks/CsvHook';
import AppContext from '../../../v2/contexts/AppContext';
import Tippy from '@tippyjs/react';
import { CSVError, ParsedCSV } from '../../../handlers/csvUtils/csvTypes';
import SegmentsConfigTable from '../Tables/SegmentsConfigTable';
import { IFilterRow } from '../../sections/Filters/FiltersTypes';
import InteractiveSection from '../../baseComponents/InteractiveSection';

export default function CSVIntegrationModal({
  modalOpen,
  source,
  callbackModal,
  confirmButton,
  loadingConfirm,
  errorMsg,
  rawErrorMsg,
  successMsg,
  callbackResetData,
  ...props
}: {
  modalOpen: boolean;
  source: string;
  callbackModal: () => void;
  confirmButton: ({ csvToUpload, source, sourceName, isCsv, isRedshift }: any) => Promise<void>;
  errorMsg: string | null;
  successMsg: string | null;
  callbackResetData: () => void;
  loadingConfirm: boolean;
  rawErrorMsg: string | null;
}) {
  // states to handle parsing
  /**
   * @description raw file to be uploaded
   */
  const [fileToUpload, setFileToUpload] = useState<File | undefined>(undefined);

  /**
   * @description takes sourceName typed by the user from the sourceName component and gives it to the csvHook for validating/uploading to backend
   */
  const [sourceName, setSourceName] = useState<string>('');
  /**
   * @description the separator used to split concatenated values in a column
   */
  const [concatenationSeparator, setConcatenationSeparator] = useState<string>(',');

  // states to handle rendering and display
  /**
   * @description bool to render preview data or actual csv upload data
   */
  const [uploadSuccess, setUploadSuccess] = useState(false);

  /**
   * @description bool whether or not to show validationErrors
   */
  const [showErrors, setShowErrors] = useState(false);

  /**
   * @description the filters used to modify segments in SegmentsConfigTable
   */
  const [filters, setFilters] = useState<IFilterRow[]>([]);

  /**
   * @description loading status of the submit button
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * @description the total number of rows uploaded to the backend
   */
  const [numRowsUploaded, setNumRowsUploaded] = useState<number>(0);

  /**
   * @description an array of errors created from validation
   */
  const [errors, setErrors] = useState<CSVError[]>([]);

  /**
   * @description easy way to decide whether or not to render a component (implemented using a useEffect listening on errors)
   */
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  /**
   * @description number of errors to render
   */
  const [numErrorsToRender, _] = useState<number>(3);

  // states to handle uploading to backend and validation
  /**
   * @description teamID needed for creating feedbackEntries mutation
   */
  const { curTeamId } = useContext(AppContext);

  /**
   * @description csvHook containing all logic for parsing, validation, and uploading
   */
  const csvHook = useCSVHook();

  /**
   * @description either a default filler data or the csv's actual data
   */
  const [displayCSV, __] = useState<ParsedCSV>(csvHook.getReadOnlySampleCSV());

  /**
   * @description the ParsedCSV the user specifies. When undefined, `displayCSV` is displayed in Preview Table
   */
  const [userUploadedCSV, setUserUploadedCSV] = useState<ParsedCSV | undefined>(undefined);

  /**
   * @description true when the Segments InteractiveSection should render and false otherwise
   *              Note: this state is controlled by the SegmentsConfigTable,
   *                    we're just exposing `hasSegmentsConfigs` to other components in CSVIntegrationModal
   */
  const [hasSegmentsConfigs, setHasSegmentsConfigs] = useState<boolean | undefined>(undefined);

  // Add new state at the top with other states
  const [shouldSplitValues, setShouldSplitValues] = useState<boolean>(true);

  /**
   * @description creates the parsedCSV to be displayed to preview table or sets any errors that occured during parsing
   */
  async function handleSelectFile() {
    // case 1) file is empty (likely because of on render useEffect Call)
    if (!fileToUpload) {
      return;
    }

    // case 2) file is not empty, upload the csv or display errors
    setIsLoading(true);
    try {
      const parsedResult = await csvHook.parse(fileToUpload);
      setHasSegmentsConfigs(parsedResult.segmentHeader && parsedResult.segmentHeader.segHeader.length != 0);
      setUserUploadedCSV(parsedResult);
    } catch (error: any) {
      // if there are parsing errors, go back to displaying `displayCSV`
      setUserUploadedCSV(undefined);

      if (error instanceof Error) {
        setErrors([new CSVError(error.message)]);
      } else {
        setErrors((prevErrors) => [...prevErrors, new CSVError('An Unknown Error occured. Please contact your Unwrap.ai Representative')]);
      }
    }
    setIsLoading(false);
  }

  /**
   * @description uploads csv to backend and displays any validation or displays upload errors
   */
  async function handleValidation() {
    // case 1) no file is uploaded or there was a parsing error
    if (!fileToUpload || !userUploadedCSV) {
      setErrors([new CSVError('Empty File: Please select a file')]);
      return;
    }

    // case 1.5) trying to upload the default data
    if (userUploadedCSV === csvHook.getReadOnlySampleCSV()) {
      setErrors([new CSVError('Unexpected Error: The CSV you uploaded was not parsed correctly')]);
      return;
    }

    // case 2) file has been uploaded
    setIsLoading(true);
    try {
      const [validatedCSV, totalRowsUploaded]: [ParsedCSV, number] = await csvHook.upload(
        userUploadedCSV,
        sourceName,
        curTeamId!,
        concatenationSeparator,
        shouldSplitValues,
        filters
      );
      setUserUploadedCSV(validatedCSV); // upload function adds an integration-id and other non-customer facing meta data for segments
      setNumRowsUploaded(totalRowsUploaded);
      setUploadSuccess(true);
    } catch (error: any) {
      // case 1) errors thrown are of CSVError[]
      if (Array.isArray(error)) {
        setErrors(error);
      }
      // case 2) generic error thrown
      else if (error instanceof Error) {
        setErrors((prevErrors) => [...prevErrors, new CSVError(error.message)]);
      }
      // case 3) should never occur, handle here
      else {
        setErrors((prevErrors) => [...prevErrors, new CSVError('An Unknown Error occured. Please contact your Unwrap.ai Representative')]);
      }
    }
    setIsLoading(false);
  }

  /**
   * @description reset all data on close
   */
  function handleClose() {
    callbackModal();
    setErrors([]);
    setUploadSuccess(false);
    setUserUploadedCSV(undefined);
    setFileToUpload(undefined);
    setSourceName('');
  }

  /**
   * @description reset all errors when a new files is uploaded.
   *            Note, nothing happens if the same file is uploaded since the file does not change
   */
  useEffect(() => {
    setErrors([]);
    setUploadSuccess(false);
    handleSelectFile();
  }, [fileToUpload]);

  /**
   * @description updates `hasErrors` based on the current `errors` length
   */
  useEffect(() => {
    setHasErrors(errors.length !== 0);
  }, [errors]);

  return (
    <Modal open={modalOpen} setOpen={callbackModal} width="w-[55rem]">
      <>
        <div className="mt-3 text-center ">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            {source} Upload
          </Dialog.Title>
        </div>

        {/* Step 1. Select and preview CSV */}
        <InteractiveSection title="Select and preview your CSV" description="Drag or select a file. Make sure your CSV has the required* columns below.">
          {/* Table Selection */}
          <SelectOrDragFile setFileToUpload={(file) => setFileToUpload(file)}></SelectOrDragFile>

          {/* Display Name of the CSV File */}
          {DisplayFileName(fileToUpload)}

          {/* Preview Table */}
          {userUploadedCSV ? <PreviewTable renderData={userUploadedCSV} /> : <PreviewTable renderData={displayCSV} />}

          {/* Error Messaging */}
          {hasErrors && errors && ErrorMessages(errors, numErrorsToRender)}
        </InteractiveSection>

        {/* Step 2. Name the Source */}
        {!hasErrors && (
          <InteractiveSection title="Name the Source" description="This will allow you to filter to feedback from this source.">
            {TextInputField(sourceName, setSourceName)}
          </InteractiveSection>
        )}

        {/* Step 3. Segments */}
        {!hasErrors && userUploadedCSV && hasSegmentsConfigs && (
          <InteractiveSection
            title="Add Custom Fields"
            description="Your CSV has columns beyond the standard columns above. You can optionally add these as custom fields that you can filter feedback on."
          >
            <SegmentsConfigTable
              parsedCSV={userUploadedCSV}
              filters={filters}
              setFilters={setFilters}
              hasSegmentsConfigs={hasSegmentsConfigs}
              setErrors={setErrors}
            />
          </InteractiveSection>
        )}

        {/* Step 4. Choose CSV Custom Field Separator */}
        {!hasErrors && (
          <InteractiveSection
            title="Specify a separator for custom fields"
            description="For columns that have multiple values concatenated into column. Specify the separator that should be used to split the values. Usually a (,) or (;)."
          >
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="split-values"
                  checked={shouldSplitValues}
                  onChange={(e) => setShouldSplitValues(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label htmlFor="split-values" className="text-sm text-gray-600">
                  Split values in custom field columns
                </label>
              </div>
              {shouldSplitValues && TextInputField(concatenationSeparator, setConcatenationSeparator)}
            </div>
          </InteractiveSection>
        )}

        {/* Upload Success Messaging */}
        {uploadSuccess && userUploadedCSV && UploadSuccessMessaging(userUploadedCSV, numRowsUploaded)}

        {/* Bottom Button Functionality */}
        <div className="mt-8 flex flex-row justify-between gap-x-4 text-center">
          {CancelButton(isLoading || uploadSuccess)}
          {SubmitButton(
            // prevent submission if there are errors or any of the required fields are empty
            errors.length !== 0 || !userUploadedCSV || sourceName === '',
            isLoading
          )}
        </div>
      </>
    </Modal>
  );

  /**
   * @description Allow a user to cancel when the have selected but not uploaded a file.
   *              Hide the cancel button on submission/loading since you can't 'un-upload' a file
   * @param hideCancel makes the cancel button invisible but maintains DOM spacing
   * @returns a Cancel button
   */
  function CancelButton(hideCancel: boolean): JSX.Element {
    return (
      <div className={hideCancel ? 'invisible' : ''}>
        <Button text={'Cancel'} variant={ButtonVariant.Tertiary} disabled={loadingConfirm || !!successMsg} onClick={() => handleClose()} />
      </div>
    );
  }

  /**
   * @description Create a submit button who is disabled until there are no more errors or loading and closes the modal when done
   * @param isNotAllowSubmit when true, disables the submit button and shows a tooltip
   * @param isLoading shows a loading bar when the upload is in progress
   * @returns a submit button
   */
  function SubmitButton(isNotAllowSubmit: boolean, isLoading: boolean) {
    return (
      <>
        <Tippy
          // hide Tippy when you are allowed to submit
          disabled={!isNotAllowSubmit}
          content="Ensure that you have (1) uploaded a file and (2) entered a source name in the fields above"
          duration={300}
        >
          <div>
            <Button
              // do not allow a submit until the file is (1)uploaded/parsed and (2) a source name is specified
              disabled={isNotAllowSubmit}
              text={uploadSuccess ? 'Done' : 'Submit'}
              onClick={() => (uploadSuccess ? handleClose() : handleValidation())}
              loadingConfirm={isLoading}
            ></Button>
          </div>
        </Tippy>
      </>
    );
  }
}

/**
 * @description Create a text field for the users to specify inputs
 * @param textInput the text input the user types in--bound to a React State
 * @param setText a React State settter for setting the `textInput`
 * @returns an input field for the user to specify the text
 */
function TextInputField(textInput: string, setText: React.Dispatch<React.SetStateAction<string>>, placeholder: string = `2023Q1 NPS Responses`): JSX.Element {
  return (
    <div className="flex w-full flex-col gap-y-0">
      <input
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blueberry focus:outline-none focus:ring-blueberry sm:text-sm"
        onChange={(e) => setText(e.target.value)}
        placeholder={placeholder}
        value={textInput}
      />
    </div>
  );
}

/**
 * @description Create a text description with the Filename of the upload file or a tooltip explaining the Example Preview Table
 * @param fileToUpload the file dragged/selected by the user
 * @returns Either a description of the Preview Table or the name of the file uploaded
 */
function DisplayFileName(fileToUpload: File | undefined): JSX.Element {
  return (
    <>
      {
        <div className="mt-1 flex w-full flex-col gap-y-0">
          {fileToUpload ? (
            <div className="flex flex-row gap-x-1 text-sm font-semibold">
              {/* Display the FileName */}
              <DocumentTextIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
              <h1 className="text-md break-all">
                Previewing: <span className="font-normal text-gray-900">{fileToUpload.name}</span>
              </h1>
            </div>
          ) : (
            <h1 className="flex flex-row gap-x-1 text-sm font-semibold">
              {/* Display the Tooltip/Description of Preview Table */}
              Example CSV <span className="font-light"></span>
              <TooltipIcon tooltipContent="Hover over columns to learn about the CSV format requirements." />
            </h1>
          )}
        </div>
      }
    </>
  );
}

// SUBMISSION MESSAGING
/**
 * @description Displays WarningMessage or SuccessMessage based on numRowsUploaded
 * @param userUploadedCSV final validated CSV
 * @param numRowsUploaded total rows uploaded and not rejected as duplicates
 * @returns Either a Warning Message if all rows are duplicates or a Success Message with the number of rows that are duplicates
 */
function UploadSuccessMessaging(userUploadedCSV: ParsedCSV, numRowsUploaded: number) {
  return (
    <div className="mt-6 flex flex-col items-center gap-y-3 ">
      {numRowsUploaded === 0
        ? WarningMessage(
            `Warning: ${userUploadedCSV.numRows} out of ${userUploadedCSV.numRows} ${
              userUploadedCSV.numRows === 1 ? 'row' : 'rows'
            } excluded because of duplicate IDs`
          )
        : SuccessMessage(
            `Success! ${numRowsUploaded} out of ${userUploadedCSV.numRows} ${
              userUploadedCSV.numRows === 1 ? 'row' : 'rows'
            } uploaded. Please check your dashboard for insights in 30 minutes.`,
            numRowsUploaded,
            userUploadedCSV
          )}
    </div>
  );
}

/**
 * @description Renders ErrorBars based on the `errors` and `maxNumErrorsToDisplay`
 * @param errors all of the CSVErrors
 * @param maxNumErrorsToDisplay the maximum number of errors to display
 * @returns up to maxNumErrorsToDisplay number of errors
 */
function ErrorMessages(errors: CSVError[], maxNumErrorsToDisplay: number): JSX.Element {
  const actualDisplay: number = maxNumErrorsToDisplay > errors.length ? errors.length : maxNumErrorsToDisplay;
  const isPlural: boolean = actualDisplay > 1;

  return (
    <div className="flex flex-col items-center gap-y-3">
      {errors.slice(0, maxNumErrorsToDisplay).map((err: CSVError, idx: number) => ErrorBar(err, idx))}
      <h1 className="mt-4 flex flex-row gap-x-1 text-sm">
        {`Showing ${actualDisplay} out of ${errors.length} ${isPlural ? 'errors' : 'error'}. Please fix the ${
          isPlural ? 'errors' : 'error'
        } and reupload your CSV.`}
      </h1>
    </div>
  );
}

/**
 * @param error a singular error
 * @param idx the index of the error in `CSVError[]`
 * @returns a Single Error with a red background
 */
function ErrorBar(error: CSVError, idx: number): JSX.Element {
  return (
    <div className="mt-4 inline-block rounded-md bg-red-100 p-2" key={idx}>
      <div className="flex flex-row gap-x-1">
        <ExclamationCircleIcon className="h-6 w-6 text-red-400" />
        <h1 className="text-sm font-medium text-red-400">
          {error.row ? `Row ${error.row}` : ``} {error.col && error.row ? ',' : ``} {error.col ? `Column ${error.col}` : ``} {error.col || error.row ? `:` : ``}{' '}
          {error.message}
        </h1>
      </div>
    </div>
  );
}

/**
 * @param warning a warning message
 * @returns A Yellow Warning message if the entire file is a duplicate (All rows of CSVs excluded by the backend)
 */
function WarningMessage(warning: string): JSX.Element {
  return (
    <div className="mt-4 inline-block rounded-md bg-orange-100 p-2">
      <div className="flex flex-row gap-x-1">
        <h1 className="text-sm font-medium text-orange-400">{warning}</h1>
        <Tippy content="All row IDs are duplicates and were not uploaded" duration={300}>
          <QuestionMarkCircleIcon className="h-4 w-4 text-orange-400" aria-hidden="true" />
        </Tippy>
      </div>
    </div>
  );
}

/**
 * @description Creates a success message that optionally displays a Tooltip description if there were rows excluded
 * @param message the success message
 * @param numRowsUploaded the total number of rows uploaded
 * @param validatedCSV the CSV (used to calcualte totla number of rows)
 * @returns a SuccessMessage with a tooltip describing the number of rows dropped
 */
function SuccessMessage(message: string, numRowsUploaded: number, validatedCSV: ParsedCSV): JSX.Element {
  // handle English Grammar
  const singleDupeMessage: string = `1 row is a duplicate and was automatically excluded from the upload`;
  const pluralDupeMessage: string = `${validatedCSV.numRows - numRowsUploaded} rows are duplicates and were automatically excluded from the upload`;

  return (
    <div className="mt-4 inline-block rounded-md bg-green-200 p-2">
      <div className="flex flex-row items-center gap-x-1">
        <h1 className="text-sm font-medium text-green-600">{message}</h1>
        {numRowsUploaded < validatedCSV.numRows && (
          <Tippy content={validatedCSV.numRows - numRowsUploaded == 1 ? singleDupeMessage : pluralDupeMessage} duration={300}>
            <QuestionMarkCircleIcon className="h-4 w-4 text-green-500" aria-hidden="true" />
          </Tippy>
        )}
      </div>
    </div>
  );
}
